import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataValue } from '../../types/models/DataValue';
import { APIHelper } from '../../services/APIHelper';
import { StoreService } from '../../services/store';
import { ApiAlaaHelper } from 'src/app/services/ApiAlaaHelper';
@Component({
  selector: 'app-loginpage',
  template: `
<div class="login-container">
  <div class="login-container-flow">
  </div>
  <div class="backhomepage" style="z-index: 99;color: white" routerLink="/">
    <div>
      <img src="../../assets/images/left_arrow.png" style="width: 25px"/>
    </div>  
    <div>
    Back to Homepage
  </div>
  </div>
<div class="card"  data-aos="zoom-out-right" data-aos-duration="500">
  <div style="text-align: center;cursor:pointer">
    <img src="../../assets/images/logo.jpg" style="width: 400px;border-radius: 5px;"/>
  </div>
  <div>
    <!-- <h4 class="card-header">Login</h4> -->
    <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="login()">
            <div class="mb-3">
                <label class="form-label">Email</label>
                <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />

            </div>
            <div class="mb-3">
                <label class="form-label">Password</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            </div>

            <button [disabled]="loading" class="primary-btn">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                    Login
            </button>
        </form>
    </div>
</div></div>
  `,
  styleUrls: ['./loginpage.component.scss']
})
export class LoginpageComponent implements OnInit {
  loading = false;
  submitted = false;
  form: FormGroup;
  errorMessage: string = "";
  selectedCountryCode: DataValue | undefined

  constructor(
    private router: Router,
    private apiAlaaService: ApiAlaaHelper,
    private fb: FormBuilder) {
      this.form = this.fb.group({
        email: ['', Validators.required],
        password: ['', Validators.required]
      });
     }

  ngOnInit(): void {
  }

  get f() { return this.form.controls; }

 login() {
  this.submitted = true;
  if (this.form.invalid) {
    return;
  }
  this.errorMessage = "";
  this.loading = true;
  if(this.form.value.email == "alaashamaly@admin.com" && this.form.value.password == "P@ssw0rd123!@#"){
    localStorage.setItem("IsAlaaShamalyAdmin", "true");
    this.router.navigateByUrl('/admin');
  } else {
    this.loading = false;
    this.errorMessage = "Invalid Credentials" 
  }

  // this.apiAlaaService.loginUser(this.form.value.email, this.form.value.password)
  //         .subscribe((result: any) => {
  //           this.loading = false;
  //           if(result){
  //             localStorage.setItem("IsAlaaShamalyAdmin", "true");
  //             this.router.navigateByUrl('/admin');
  //           } else {
  //             this.errorMessage = "Invalid Credentials" 
  //           }
  //         })

  }

}
