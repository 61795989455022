import { Component, HostListener, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import * as AOS from 'aos';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  template: `<router-outlet (activate)="onActivate($event)"></router-outlet>`
})
export class AppComponent implements OnInit{
  title = 'frontend';

  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(e: KeyboardEvent) {
  //   console.log(e)
  //   if (e.key === 'F12') {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "I") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "C") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.shiftKey && e.key === "J") {
  //     return false;
  //   }
  //   if (e.ctrlKey && e.key == "U") {
  //     return false;
  //   }
  //   return true;
  // }
  constructor(  
    private titleService: Title,  
    private metaTagService: Meta  
  ) { } 
  ngOnInit(): void {
     AOS.init({
      disable: false,
      startEvent: 'DOMContentLoaded',
      initClassName: 'aos-init',
      animatedClassName: 'aos-animate', 
      disableMutationObserver: false,
      debounceDelay: 250,
      throttleDelay: 99,
      useClassNames: false,
      offset: 120,
      duration: 400,
      easing: 'ease',
      once: false,
      mirror: false, 
      anchorPlacement: 'top-bottom'
     });

    this.titleService.setTitle("Alaa Shamaly Photography");  
    this.metaTagService.addTags([  
      { name: 'keywords', content: 'Alaa Shamaly, Photography, Lebanon, Camera, Pre Wedding, Engagement, Wedding, photo session, photo, picture, Shamaly Photo, shooting' },  
      { name: 'writer', content: 'Alaa Shamaly Photography' },  
      { charset: 'UTF-8' } ,
       // Additional tags
    { property: 'og:title', content: 'Alaa Shamaly Photography' },
    { property: 'og:description', content: "Capture life's precious moments with Alaa Shamaly Photography, a premier photography specializing in stunning wedding, portrait, and event photography. Our experienced team combines creativity with cutting-edge technology to deliver timeless images you'll cherish forever. Let us tell your unique story through our lens, ensuring every shot is picture-perfect." },
    { property: 'og:image', content: 'https://alaashamaly.com/assets/images/logo-sm.jpg' },
    { property: 'og:url', content: 'https://alaashamaly.com' } 
    ]);  

    
    
  }

  onActivate(event: any) {}
}
