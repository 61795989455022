import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { map, finalize } from "rxjs/operators";
import { StoreService } from './store';
import { HttpClient } from '@angular/common/http';
import { UserInfo } from '../types/models/UserInfo';
@Injectable({
  providedIn: 'root',
})
export class ApiAlaaHelper {
  constructor(
    private http: HttpClient,
    private store: StoreService
  ) {}

  url = 'https://shmly.alaashamaly.com';
  //url = 'http://localhost:5000'
  getProducts(){
    return this.http.get(`${this.url}/products`)
  }

  get4Products(){
    return this.http.get(`${this.url}/products4`)
  }

  get4ProductsByCategory(){
    return this.http.get(`${this.url}/productscategory`)
  }
  

  addEditProduct(data: any){
    return this.http.post(`${this.url}/products`, data)
  }

  deleteImage(data: any){
    return this.http.post(`${this.url}/image`, data)
  }

  deleteCarousel(carouselId: string){
    return this.http.delete(`${this.url}/carousel/${carouselId}`)
  }

  deleteCategory(cateogryId: string){
    return this.http.delete(`${this.url}/category/${cateogryId}`)
  }

  deleteProduct(productId: string){
    return this.http.delete(`${this.url}/product/${productId}`)
  }
  
  getProductById(productId: string){
    return this.http.get(`${this.url}/product/${productId}`)
  }

  getProductsByCategories(categoryId: string){
    return this.http.get(`${this.url}/products/${categoryId}`)

  }

  getCarousels(){
    return this.http.get(`${this.url}/carousels`)
  }


  
  getCarouselById(id: string){
    return this.http.get(`${this.url}/carousels/${id}`)
  }

  getImage(imageName: string){
    return `${this.url}/resources/images/${imageName}`
  }

  addEditCarousel(data: any){
    return this.http.post(`${this.url}/carousels`, data)

  }

  getCategories(){
    return this.http.get(`${this.url}/categories`)
  }

  getCategoryById(id: string){
    return this.http.get(`${this.url}/categories/${id}`)
  }

  addEditCategory(data: any){
    return this.http.post(`${this.url}/categories`, data)
  }

  getMessages(){
    return this.http.get(`${this.url}/messages`)
  }

  addMessage(name: string, email: string, subject: string, body: string){
    return this.http.post(`${this.url}/messages`, {
      name,
      email,
      subject,
      body
    })
  }

  loginUser(email: string, password: string){
    return this.http.post(`${this.url}/login`, {
      email,
      password
    })
  }
}
