import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserInfo } from '../types/models/UserInfo';
import { APIHelper } from './APIHelper';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public products: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public productsInCart: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );
  public productsInWishlist: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  public threeProducts: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public categories: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public userInfo: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>({
    Id: undefined,
    Address1: '',
    Addressid: '',
    Email: '',
    Lat: '',
    Lng: '',
    name: '',
    Phone: '',
  });

  constructor(private httpService: APIHelper) {
    const userInfoLocalData = localStorage.getItem('UserInfo');
    if (userInfoLocalData) {
      this.userInfo.next(JSON.parse(userInfoLocalData));
    }
  }

  clearBehaviours() {}
}
