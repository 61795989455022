import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { map, finalize } from "rxjs/operators";
import { StoreService } from './store';
import { HttpClient } from '@angular/common/http';
import { UserInfo } from '../types/models/UserInfo';
@Injectable({
  providedIn: 'root',
})
export class APIHelper {
  constructor(
    private http: HttpClient,
    private store: StoreService
  ) {}
  url = 'http://cosmatic.skyweb-lb.com/';
  //url = 'http://localhost:55391/';
  UpdateAddress(userId: any, longitude: any, latitude: any, address: string, addressId: number){
    return this.http.post(`${this.url}api/ExternalAuthApp/UpdateAddress`, {
      UserId: userId,
      Longitude: longitude,
      Latitude: latitude,
      Address: address,
      AddressId: addressId
    })
  }

  GetCustomerInfo(){

    const userInfoLocalData = localStorage.getItem('UserInfo');
    if(userInfoLocalData){
      const _userInfo = JSON.parse(userInfoLocalData);
      this.http.post(`${this.url}api/ExternalAuthApp/FindCustomer`, {
        Phone: _userInfo.Phone
      })
      .subscribe((result: any) => {
        const updatedUserInfo: any = {
            Email: result.Email,
            Id: result.id,
            name: result.FirstName,
            Address1: "",
            Addressid: "",
            Lat: "",
            Lng: "",
            Phone: result.PhoneNumber,
        }
        if(result.lstShippingAddress.length > 0){
          const address = result.lstShippingAddress[0];
          updatedUserInfo.Address1 = address.Address1;
          updatedUserInfo.Lat = address.Latitutde;
          updatedUserInfo.Lng = address.Longitutde;
          updatedUserInfo.Addressid = address.AddressId
        }
        localStorage.setItem("UserInfo", JSON.stringify(updatedUserInfo));
        this.store.userInfo.next(updatedUserInfo);
      })
    }

  }

  UpdateCustomer(id: string, name: string, email: string){
    return this.http.post(`${this.url}api/ExternalAuthApp/UpdateCustomer`, {
      Id: id,
      name: name,
      email: email
    })
  }

  SearchCustomerOrders(userId: any){
    return this.http.post(`${this.url}api/RestaurantAP/SearchCustomerOrders`, {
      CustomerId: userId
    })
  }

  AddOrder(amount: number, products: any[], userInfo: UserInfo){
    return this.http.post(`${this.url}api/RestaurantAP/AddOrder`, {
      CustomerId: userInfo.Id,
      Amount: amount,
      DiscountAmount: amount,
      CustomerCurrencyCode: "USD",
      NameOneCard: "",
      CardNumber: "",
      PaymentMethod: "Cash",
      Token: "",
      TransactionId:  this.generateGUID(),
      ShippingStatusId: 0,
      Products: products,
      AddressId: userInfo.Addressid,
      lat: userInfo.Lat,
      lng: userInfo.Lng
    })
  }

  generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
    v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
    });
   }

  SearchProducts(searchTerm: any){
    return this.http.get(`${this.url}api/RestaurantAP/SearchProducts?PrdEn=${searchTerm}`)
  }

  GetProductById(productId: number){
    return this.http.post(`${this.url}api/ProductAP/ProductDetails`, {
      ProductId: productId
    })
  }

  GetProductsAddedToCart() {
    const storedProducts = localStorage.getItem('products');
    const storedProductsParsed = storedProducts ? JSON.parse(storedProducts) : [];
    this.store.productsInCart.next(storedProductsParsed);
    return storedProductsParsed;
  }

  GetProductsAddedToWihslist() {
    const storedProducts = localStorage.getItem('wishlistProducts');
    const storedProductsParsed = storedProducts ? JSON.parse(storedProducts) : [];
    this.store.productsInWishlist.next(storedProductsParsed);
    return storedProductsParsed;
  }

  ClearCart(){
    var products: any[] = [];
    this.store.productsInCart.next(products);
    localStorage.setItem('products', JSON.stringify(products));
  }

  RemoveProductFromCart(product: any){
    const storedProducts = localStorage.getItem('products');
    var storedProductsParsed = storedProducts ? JSON.parse(storedProducts) : [];
    if(storedProductsParsed.length > 0){
      storedProductsParsed = storedProductsParsed.filter((x:any) => x.Id != product.Id);
    }
    this.store.productsInCart.next(storedProductsParsed);
    localStorage.setItem('products', JSON.stringify(storedProductsParsed));
  }

  RemoveProductFromWishlist(product: any){
    const storedProducts = localStorage.getItem('wishlistProducts');
    var storedProductsParsed = storedProducts ? JSON.parse(storedProducts) : [];
    if(storedProductsParsed.length > 0){
      storedProductsParsed = storedProductsParsed.filter((x:any) => x.Id != product.Id);
    }
    this.store.productsInWishlist.next(storedProductsParsed);
    localStorage.setItem('wishlistProducts', JSON.stringify(storedProductsParsed));
  }

  addUpdateProductInWishlist(product: any): void {
    var products = this.GetProductsAddedToWihslist();
    var _product = products.find((x:any) => x.Id == product.Id);
    if(_product){
      products = products.filter((x:any) => x.Id != product.Id)
    } else {
      products.push(product);
    }
    localStorage.setItem('wishlistProducts', JSON.stringify(products));
    this.store.productsInWishlist.next(products);
  }

  addUpdateProductInCart(product: any, type: any = "increment"): void {
    var products = this.GetProductsAddedToCart();
    if(products.length > 0){
      var _product = products.find((x:any) => x.Id == product.Id);
      if(_product){
        if(type == "decrement"){
          if(_product.Quantity > 1){
            _product.Quantity -= 1;
          }
        } else {
          _product.Quantity += 1;
        }
      } else {
        products.push(product);
      }
    } else {
      products.push(product);
    }
    localStorage.setItem('products', JSON.stringify(products));
    this.store.productsInCart.next(products);
  }


  incrementProductQantity(product: any){
    this.addUpdateProductInCart(product, "increment");
  }

  decrementProductQuantity(product: any){
    this.addUpdateProductInCart(product, "decrement");

  }

  

  GetCategories(){
    var categories = this.http.post(`${this.url}api/RestaurantAP/Categories`, {}).subscribe((result: any) => {
      if(result && result.Categories){
        this.store.categories.next(result.Categories);
      }
    });
    return categories;
  }

  Get3Products(){
    var products = this.http.get(`${this.url}api/RestaurantAP/NewProducts`).subscribe((result: any) => {
      if(result && result.Products){
        this.store.threeProducts.next(result.Products);
      }
    });
    return products;
  }

  GetProducts(){
    var products = this.http.get(`${this.url}api/RestaurantAP/Products`).subscribe((result: any) => {
      if(result && result.Products){
        console.log('Tracee', result.Products)
        this.store.products.next(result.Products);
      }
    });
    return products;
  }

  GetProductsByCategoryId(categoryId: any){
    var products = this.http.get(`${this.url}api/RestaurantAP/Products?id=${categoryId}`).subscribe((result: any) => {
      if(result && result.Products){
        this.store.products.next(result.Products);
      }
    });
    return products;
  }

  Login(phoneNumber: string, password: string){
    const data = {
      phoneNumber: phoneNumber,
      password: password
    }
    return this.http.post(`${this.url}api/LoginAp/LoginUser`, data);
  }

  RegisterUser(phoneNumber: string, password: string, email: string, name: string){
    const data = {
      Username: email,
      Password: password,
      Phone: phoneNumber,
      name: name,
      email: email
    }
    return this.http.post(`${this.url}api/LoginAp/RegisterUser`, data);
  }
  
  ChangePassword(phoneNumber: string, newPassword: string){
    const data = {
      phoneNumber: phoneNumber,
      NewPassword: newPassword
    }
    return this.http.post(`${this.url}api/LoginAp/ChangePassword`, data);
  }
}
