import { LoginpageComponent } from './auth/loginpage/loginpage.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RegisterpageComponent } from './auth/registerpage/registerpage.component';
import { ForgotpasswordpageComponent } from './auth/forgotpasswordpage/forgotpasswordpage.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  { path: 'login', component: LoginpageComponent },
  { path: 'register', component: RegisterpageComponent, data: { animationState: 'isRight' } },
  { path: 'forgotpassword', component: ForgotpasswordpageComponent, data: { animationState: 'isRight' } },
  // {
  //   path: '', loadChildren: () => import('./main/main.module') .then(m => m.MainModule),
  //    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  // },
  {
    path: '', loadChildren: () => import('./main/main.module') .then(m => m.MainModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
