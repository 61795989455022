import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataValue } from '../../types/models/DataValue';
import { APIHelper } from '../../services/APIHelper';
import { StoreService } from '../../services/store';
import * as firebase from 'firebase';
@Component({
  selector: 'app-forgotpasswordpage',
  template: `
<div class="login-container">
  <div class="backhomepage"  routerLink="/">
    <div>
      <img src="../../assets/images/left_arrow.png" style="width: 25px"/>
    </div>  
    <div>
    Back to Homepage
  </div>
  </div>
<div class="card"  data-aos="zoom-out-right" data-aos-duration="500">
  <div style="text-align: center;cursor:pointer" routerLink="/">
      <label>Please enter your phone number below. Your will receive a verification code and then you will be able to create a new password</label>
  </div>
  <div>
    <!-- <h4 class="card-header">Login</h4> -->
    <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="login()">
            <div class="mb-3">
                <label class="form-label">Phone Number</label>
                <div style="display: flex;column-gap: 10px">
                  <p-dropdown
                      [options]="countries"
                      style="display: block"
                      formControlName="countryCode"
                      optionLabel="Text"
                      optionValue="Value"
                  ></p-dropdown>
              
                  <input type="number" formControlName="phoneNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" />
                </div>
            </div>
            <div style="display: flex;align-items: center;flex-direction: column;">
                <div *ngIf="submitted && errorMessage" class="invalid-login">
                    <div >{{errorMessage}}</div>
                </div>

                <button [disabled]="loading" class="primary-btn">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
                    Verify
                </button>
                <a routerLink="/login" style="margin-top: 20px;" class="btn btn-link">Login</a>
            </div>
        </form>

        <p-dialog [draggable]="false" header="" [(visible)]="displayPhoneVerification">
            <div style="
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;
">
            <img src="../../assets/images/phoneverification.gif" style="width: 200px"/>
              <label style="color: black;font-weight: bold;font-size: 20px;">Enter the OTP Code from Phone Number</label>
              <label>The 6-digit verification sent to {{phoneNumberText}}</label>
              <ng-otp-input [(ngModel)]="OtpVerification" [config]="{length:6}"></ng-otp-input>
              <button class="primary-btn margin-10 padding-10-20" (click)="VerifyPhoneNumber()" style="margin-top: 50px;" type="submit">
              <span *ngIf="loadingVerification" class="spinner-border spinner-border-sm me-1"></span>
               Verify
             </button>
          </div>    
        
      </p-dialog>

      
      <p-dialog [draggable]="false" header="" class="password-change-container" [(visible)]="showChangePasswordPopup">
            <div style="
        display: flex;
        flex-direction: column;
        row-gap: 10px;
">
              <label style="color: black;font-weight: bold;font-size: 20px;">New Password</label>
              <input class="form-control" type="password" [(ngModel)]="newPassword" />

              <label style="color: black;font-weight: bold;font-size: 20px;">Password Confirmation</label>
              <input class="form-control" type="password" [(ngModel)]="newPasswordConfirmation" />

              <div *ngIf="changePasswordSubmitted && changePasswordErrorMessage" class="invalid-login">
                    <div >{{changePasswordErrorMessage}}</div>
                </div>


              <button class="primary-btn margin-10 padding-10-20" (click)="ChangePassword()" style="margin-top: 50px;" type="submit">
              {{isProcessing ? "Loading": "Save"}}
             </button>
          </div>    
        
      </p-dialog>

      <p-dialog [draggable]="false" header="" (onHide)="GoToLoginPage()" [(visible)]="isPasswordChangedSuccess">
            <div style="
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 10px;
">
            <img src="../../assets/images/success.png" style="width: 200px"/>
              <label style="color: black;font-weight: bold;font-size: 20px;">Password Changed Successfully</label>
              <label style="text-align: center">Your password has changed successfully, Login and stay up to date on our products!</label>
              <button class="primary-btn margin-10 padding-10-20" (click)="GoToLoginPage()" style="margin-top: 50px;" type="submit">
                Login
             </button>
          </div>    
        
      </p-dialog>
    </div>
</div></div>
  `,
  styleUrls: ['./forgotpasswordpage.component.scss']
})
export class ForgotpasswordpageComponent implements OnInit {
  loading = false;
  submitted = false;
  form: FormGroup;
  countries: DataValue[]
  errorMessage: string = "";
  selectedCountryCode: DataValue | undefined
  phoneNumberText: string = "";
  displayPhoneVerification = false;
  changePasswordSubmitted: boolean = false;
  changePasswordErrorMessage = "";
  OtpVerification: string = "";
  phoneNumberConfirmationResult: firebase.auth.ConfirmationResult | undefined
  loadingVerification = false;
  showChangePasswordPopup: boolean = false;

  newPassword: string = "";
  newPasswordConfirmation: string = "";
  isProcessing = false;

  isPasswordChangedSuccess: boolean = false;
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private httpService: APIHelper,
    private store: StoreService,
    private fb: FormBuilder) {
      this.countries = [
        { Text: "+961", Value: "961"},
        { Text: "+48", Value: "48"},
        { Text: "+818", Value: "818"},
        { Text: "+368", Value: "368"},
        { Text: "+400", Value: "400"},
        { Text: "+414", Value: "414"},
      ]

      this.form = this.fb.group({
        phoneNumber: ['', Validators.required],
        password: ['', Validators.required],
        countryCode: [this.countries[0]]
      });
     }

  ngOnInit(): void {
  }

  get f() { return this.form.controls; }

 login() {
  this.phoneNumberText = this.form.value.countryCode.Text + this.form.value.phoneNumber;
  const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container'); // make sure you have a DOM element with id 'sign-in-button'
  this.afAuth.signInWithPhoneNumber(this.form.value.countryCode.Text + this.form.value.phoneNumber, appVerifier)
    .then(confirmationResult => {
      this.submitted = false;
      this.loading = true;
      this.displayPhoneVerification = true;
      this.phoneNumberConfirmationResult = confirmationResult;
    })
    .catch(error => {
      this.submitted = false;
      this.errorMessage = "SMS not sent, try again";
    });
  }

  VerifyPhoneNumber() {
    this.phoneNumberText = this.form.value.countryCode.Text + this.form.value.phoneNumber;
    const phoneNumberValue = this.form.value.countryCode.Value + this.form.value.phoneNumber;
    const name = this.form.value.firstName + " " + this.form.value.lastName;
    this.loadingVerification = true;
    if(this.phoneNumberConfirmationResult){
      this.phoneNumberConfirmationResult.confirm(this.OtpVerification)
      .then(result => {
        this.httpService.RegisterUser(
          phoneNumberValue, this.form.value.password, this.form.value.email, name)
                        .subscribe((result: any) => {
                          if(result.Result == "success"){
                              this.loading = false;
                              this.displayPhoneVerification = false;
                              this.showChangePasswordPopup = true;
                          } else {
                            this.errorMessage = result.message;
                            this.displayPhoneVerification = false;
                            this.showChangePasswordPopup = false;
                            this.loading = false;
                          }
                        })
      })
      .catch(error => {
        console.error('Verification code is incorrect', error);
      });
    }
  
  }

  GoToLoginPage() {
    this.router.navigateByUrl("/login")
  }

  ChangePassword(){
    this.changePasswordErrorMessage = ""
    this.changePasswordSubmitted = true;
    if(this.newPassword.length < 6){
      this.changePasswordErrorMessage = "Password must be greater than 6 characters";
      this.loading = false;
      return;
    }
    if(this.newPassword != this.newPasswordConfirmation){
      this.changePasswordErrorMessage = "Password not matching";
      this.loading = false;
      return;
    }

    this.isProcessing = true;

    this.httpService.ChangePassword(this.form.value.countryCode.Value + this.form.value.phoneNumber, this.newPassword)
                    .subscribe((result: any) => {
                      this.isProcessing = false;
                      if(result.Result == "success"){
                        this.showChangePasswordPopup = false;
                        this.isPasswordChangedSuccess = true;
                      } else {
                        this.changePasswordErrorMessage = result.message
                      }
                    })
  }

}
